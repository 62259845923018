.transfer__search_result {
 @include search-info;
 .search-details {
  @include create-main-wrapper;
  background-color: $md-bgcolor;
  &_wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  &__row {
    @include clearfix;
    // margin-left: 15px;
    > li {
      @include make-one-fifth;
      padding-left: 0;
      padding-right: 0;
      border-right: 2px solid $md-border-color;
      &:first-child {
        border-left: 2px solid $md-border-color;
      }
    
      &:nth-child(3) {
        border-right: 2px solid $md-border-color;
      }
      
      &:nth-child(4) {
        border-right: none;
        
      }
      &:last-child {
        border-right: none;
      }
    }
    
    
    &_traveller {
      margin-right: -15px;
    }
    
    
  }


  .pickup-info,
  .dropoff-info,
  .destination,
  .pickup-location,
  .dropoff-location {
    
    &__data,
     {
      @include clearfix;

      // border-left: 2px solid white;
      padding-left: $base-padding*0.5;
      padding-right: $base-padding*0.5;
      &_icon {
        float: left;
        font-size: 2.25rem;
        width: 20%;
      }
      &_detail {
        float: left;
        padding: 9px;
        width: 80%;
        .location,
        .check-in,
        .check-out {
          color: $md-heading-color;
        }
        .departure,
        .checkin-date,
        .checkout-date {
          @include clearfix;
        }
      }
    }
  }

  .destination__data,
  .pickup-info__data,
  .dropoff-info__data,
  .pickup-location,
  .dropoff-location
   {
    &_icon {
      span.glyphicon.glyphicon-arrow-right,
      span.glyphicon.glyphicon-arrow-left,
      span.glyphicon.glyphicon-map-marker{
      //  transform: rotate(45deg);
        top: 5px;
            }
    }
  }

  .transfer-traveller,
  .pickup-dropoff-location {
   &__row {
     @include clearfix;
     > li {
       text-align: center;
       width: 50%;
       border-left: 2px solid $md-border-color;
       padding-top: 9px;
       padding-bottom: 9px;
       float: left;
       &:last-child {
         border-right: 2px solid $md-border-color;
       }
     }
     &_adult, &_child,&_pickup,&_dropoff {
      .adult, .child, .pickup, .dropoff {
		   &__data_heading {
		       color: $md-heading-color;
		   }
   	   }
   }
   }

 }
}

}