.transfer {
	@include create-search-form;
	@include create-search-form-nav;

	&__search-vehicle-type{
		margin-left: 0;
	    margin-right: 0;
	}

  &__search-pickupdropoff-container,
  &__search-vehicle-type, {
    margin-bottom: 1rem;
  }

  &__search-vehicle-type {
    @include clearfix;
    background-color: $sf-triptype-bgcolor;
    padding-top: 0.5rem;
    padding-left: $base-padding*0.5;
    padding-right: $base-padding*0.5;
    .radio-inline {
      @include make-one-third;
    }
  }

  &__search-pickupdropoff-container {
      @include make-row;
      &_pickup-wrapper, &_dropoff-wrapper {
        @include make-one-half;
      }
  }

  &__search-airport-select-container {
    @include make-row;
    &_pickup-point-airport, &_dropoff-point-airport {
      @include make-one-half;
    }
  }

  &__search-time, &__search-date {
  @include make-row;
   margin-bottom: 1rem;
  	&_select{
  	@include make-one-half;
  	}
  }
  
  &__search-currency {
  	&_driver-age{
  		.age{
  			&__wrapper{
  				>select{
  					width:50%;
  				}
  			}
  		}
  	}
  }
  
	&__search-traveller {
		.age {
			&__wrapper {
				padding-left: 0.4375rem;
				padding-right: 0.4375rem;
				width: 50%;
				&_transfer-age-selector {
					float: left;
					min-height: 1px;
					width: 25%;
					padding-left: 0.4375rem;
					padding-right: 0.4375rem;
					&:nth-child(5),
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						margin-top: 10px;
					}
					.age-select{
						padding: 0.5rem 0;
					}
				}
			}
		}
	}

}

.pickup-wrapper, .dropoff-wrapper {
  &__pickup-point, &__dropoff-point {
      &_title {
        color: $brand-color-2;
        font-size: .875rem;
      }
  }

  &__pickup-point-airport, &__dropoff-point-airport, &__pickup-point-hotel, &__dropoff-point-hotel, &__pickup-point-station, &__dropoff-point-station, &__pickup-point-port, &__dropoff-point-port {
    margin-top: 1rem;
    &_title {
      color: $brand-color-2;
      font-size: .875rem;
    }
  }

}

