.transfer-service-details{
	&__service-wrapper{
		margin-bottom: 1rem;
		@include make-row;
      	&_service-type,&_local-package{
        	@include make-one-half;
			.service-type,.local-package{
				&__title{
			        color: $brand-color-2;
	    			font-size: .875rem;
				}
			}
		}
	}
}