@use "sass:math";

.transfer__search_result {
  .search__result {
    @include create-main-wrapper;
    
    &_row {
      position: relative;
      @include create-recommended-product-icon();
    }
  }
}
.transfer-search-result-container {
  @include clearfix;
  width: 100%;
  padding-top: 1.875rem;
  &__sidebar {
    width: 25%;
    float: left;
    padding-left: 0;
  }
  &__promo-code{
    margin-left: -15px;
    margin-right: -15px;
  }
  &__tax-header{
	  @include clearfix;
		margin-top: -22px;
		margin-left: 18.8rem;
		padding: 0.25rem;
	    color: $dark-green;
	    background-color: $saffron;
	    box-shadow: $row-shadow-color;
	    margin-bottom: 1rem;
	    	&_tax-message{
	    		font-size: 0.75rem;
	    		padding-left: 0.25rem;
	    	}
  }
  &__content {
    width: 75%;
    float: left;
    .search__result {
      @include create-main-wrapper;
      @include create-row;
      @include create-row-card;
      @include create-row-card-footer;
      &_card {
        box-shadow: $row-shadow-color;
      }
    }
    .transfer-result-row {
      &__content {
        width: 100%;
        &_transfer-image {
          float: left;
		  width: 20%;
    	  display: flex;
    	  align-items: center;
    	  align-content: center;
    	  justify-content: center;
        }
        &_transfer-detail {
          @include make-three-fifth;
          padding-left: 0;
          padding-right: 0;
          border-left: 1px solid $brand-color-3;
          border-right: 1px solid $brand-color-3;
        }
        &_transfer-price {
          @include make-one-fifth;
        }
      }
      &__footer {
        width: 100%;
      }
    }
    .transfer-result-content__transfer-image {
      padding: 0;
      width: auto;
      height: 120px;
    }
    .transfer-details {
      &__transfer-title {
        padding-top: math.div($base-padding, 3);
        @include clearfix;
        width: 100%;
        padding-bottom: math.div($base-padding, 3);
        &_transfer-name {
          max-height: 100px;
          text-align: left;
          color: #087dc2;
          font-size: 1.14rem;
          font-weight: 400;
          text-transform: capitalize;
          margin-left: 1rem;
        }
        &_transfer-model {
          text-align: left;
          text-transform: capitalize;
          margin-left: 1rem;
        }
        &_transfer-erp-name{
          max-height: 100px;
          text-align: left;
          color:  #00F;
          font-size: 1.14rem;
          font-weight: 400;
          text-transform: capitalize;
        }
      }
      &__transfer-info {
        padding-top: math.div($base-padding, 3);
        @include clearfix;
        width: 100%;
        &_transfer-trip-data {
          width: 100%;
          float: left;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .transfer-info__transfer-trip-data {
      @include clearfix;
      &_title {
        text-transform: capitalize;
        @include make-block;
        width:90%;
        padding-left: 0;
        padding-right: 0;
        color:$dark-gray;
        margin-top: 0.1875rem;
      }
      &_icon{
      @include make-block;
      width:10%;
      color:$gray;
      text-align: center;
      	.fa-stack{
      	    margin-left: -0.0625rem;
      	}
      }
    }

    .transfer-content__transfer-price {
      &_data {
      	position: absolute;
      	height: 100%;
        @include clearfix;
        &-value {
          color: red;
          font-size: 1.125rem;
          font-family: $font-family-number;
        }
      }
      &_extra-info {
      	position: absolute;
      	width: 16%;
      	bottom: 0px;
        padding: 5px;
        text-align: center;
      }
      &_adhoc-amount{
      	display: inline-block;
   	  	width: 100%;
    	text-align: center;
      }
      
      &_amount-with-adhoc{
          font-size: 1.125rem;
          font-family: $font-family-number;
       	  color : $brand-color-14;
	
      }
    }
    .transfer-result-footer {

      // padding-left: 0.9375rem;
      // padding-right: 0.9375rem;
      @include clearfix;
      min-height: 1px;
      width: 100%;
      background-color:$brand-color-4;
      &__details {
        @include make-one-fifth;
        padding-left: math.div($base-padding, 3);
        padding-right: math.div($base-padding, 3);
        &_button {
    	  @include button-size(math.div($base-padding, 6), 0, 0.625rem, inherit, 0);
          @include make-button($light, $brand-color-3, $light, darken($brand-color-3,10));
          width: 100%;

          padding-top: math.div($base-padding, 6);
          padding-bottom: math.div($base-padding, 6);
          display: block;
          text-align: center;
          text-transform: uppercase;
          color: $brand-color-2;
          &:hover,
     	  &:active,
      	  &:focus {
        	 cursor: pointer;
    	 	 text-transform: uppercase;
    		 font-size: .625rem;
    	  	 font-weight: 600;
         	 color: $row-flight-details-button-state;
          	 text-decoration: none;
      	  }
      	  &:hover {
      	 	 background-color:$brand-color-3;
         	 text-decoration: underline;
          }
        }
      }
      &__extra-details {
        @include make-one-half;
        padding-left: math.div($base-padding, 3);
        padding-right: math.div($base-padding, 3);
        float: left;
        width: 60%;
		&_supplier{
			padding-top: 0.28rem;
    		float: left;
		}
        &_cancellation-link {
            width: 30%;
            float: right;
            margin-right: 10%;
        }

        &_button {
          width: 100%;
          background-color: $row-flight-details-button-bgcolor;
          padding-top: math.div($base-padding, 6);
          padding-bottom: math.div($base-padding, 6);
          display: block;
          text-align: center;
          color: $dark;
          &:hover,
          &:active,
          &:focus {
            color: $row-flight-details-button-state;
            text-decoration: none;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        
        &_erp-detail {
          font-size: 1.2rem;
    	  color: $brand-color-2;
    	  padding-left: 0.5rem;
    	  padding-top: 0.2rem;
          float: left;
        }
      }
	  &__highlight-duplicate{
        float: left;
        font-size:1.2rem;
        color:$brand-color-1;
      	width:5%;
      	.highlight-duplicate{
        	&__button{
        		background-color: $row-footer-bgcolor;
        	}
        }
      }
    	
      &__book {
        @include make-one-fifth;
        padding-left: math.div($base-padding, 3);
        padding-right: math.div($base-padding, 3);
        float: right;
        &_button {
          @include button-size(math.div($base-padding, 6), 0, 0.625rem, inherit, 0);
          @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
          width: 100%;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
}
.transfer-extra-details {
  .nav-tabs {
  	border-bottom: none;
  	color: $brand-color-2;
    background-color: $light;
    border: none;
    cursor: default;
	   li.active > a,
     li.active > a:hover,
     li.active > a:focus {
        background-color: $light;
        border: none;
        color: $brand-color-2;
        cursor: default;
      }

      li > a {
        margin-right: 0;
		    border: none;
		    -webkit-border-radius: 0;
		    border-radius: 0;
		    color: $brand-color-2;

        &:hover, &:focus {
		  		color: $brand-color-2;
			    background-color: $light;
			    border: none;
		    }
      }
  /*	li {
      .active {
        background-color: $light;
      }

  /*		.active {
  			background-color: $light;
  			>a  {
  				    color: #087dc2;
				    background-color: $light;
				    border: none;
				    cursor: default;
  			}
  		}
  		>a {
	  		margin-right: 0;
		    border: none;
		    -webkit-border-radius: 0;
		    border-radius: 0;
		    color: #087dc2;
		    //font-size: .875rem;
		    cursor: default;

		    &:hover, &:focus {
		  		color: #087dc2;
			    background-color: $light;
			    border: none;
			    cursor: default;
		    }
	    }
  	}*/
  }

  .nav {
  	//margin-left: -.9375rem;
    //margin-right: -.9375rem;
    background-color: $brand-color-3;
  }

  margin-top: -0.9375rem;
  //padding-left: $base-padding/3;
  //padding-right: $base-padding/3;
  padding-bottom: math.div($base-padding, 3);
  &__header {
    background-color: $brand-color-4;
    border-left: 10px solid $brand-color-3;
    border-right: 10px solid $brand-color-3;
    border-top: 10px solid $brand-color-3;
  }
  &__list {

    // display: inline-block;
    @include clearfix;
    >li {
      padding-top: 5px;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
      float: left;

      // border-top: 3px solid $brand-color-3;
      // border-left: 3px solid $brand-color-3;
      // border-right: 3px solid $brand-color-3;
      > a {
        color: $brand-color-5;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
      }

      //
      // .active{
      //   background-color: $brand-color-3;
      //   color: $brand-color-2;
      // }
    }
  }
  background-color: $light;
  &__tab-content {
    //border-left: 10px solid $brand-color-3;
    //border-right: 10px solid $brand-color-3;
    //border-bottom: 10px solid $brand-color-3;

    // padding: 5px;
    &_data {
      padding: 20px;
      
      .pickup-details{
      	float: left;
      }
      .dropoff-details{
      	float: right;
      }
      .pickup-details, .dropoff-details{
		width: 49%;
		&__header{
			font-weight: bold;
		    font-size: 1rem;
		    background-color: $brand-color-3;
		    padding: 0.3rem;
		    padding-left: 11rem;
		}
		&__icon {
			color:$brand-color-2;
			font-size: 1.2rem;
    		padding: 0.5rem;
		}
		&__location, &__location-info,  &__address, &__contact-no, &__working-hours {
			padding-right: 3rem;
   			padding-left: 1rem;
			&_label{
				color:$brand-color-2;
				font-size: 0.9rem;
			}
		}
		&__working-hours {
			&_content{
				.working-hours{
					&__detail{
					    width: 28%;	
					}
					&__icon {
						margin-left: 2.5rem;
						margin-right: 0.3rem;
						color:$brand-color-2;
					}
				}
			}
		}
		
		
      }
      
    }
  }
  .active {
    background-color: $light;
    a {
      color: $brand-color-1;
    }
  }
  .transfer-tab-content__data {
    &_transfer-details {
      padding: 5px;
      text-transform: capitalize;
    }
    &_terms-url {
      padding: 2px;
      font-size: 1rem;
    }
  }
	.highlight-tab {
  		border-bottom: none;
		.nav-link{
  			color: $royal-red !important;
		}
	}
}

.modal{
	&__content{
		&_transfer-cancellation-loader,&_transfer-details-loader{
			@include make-loader;
			}
	}
}
.transfer-result-price-status{
	position: absolute;
  	width: 18%;
  	bottom: 0px;
  	text-align: center;
	&__available,
  	&__unavailable,
	&__price-changed{
		margin: 0 auto;
	    max-width: 150px;
	    color: $orange;
	    text-transform: uppercase;
	}
	&__available{
	    color: $green;
	}
	&__unavailable{
	    color: $brand-color-9;
	}
}
.popover_link{
	color: $water-blue;
}

.transfer-search-result-modal {
	&__modal-content {
	    overflow-y: auto;
	    margin-top: 0;
	    margin-bottom: 5px!important;
	    padding-top: .9375rem;
	}
	&__modal-header {
	    padding-bottom: 0; 
	}
}
